<template>
  <CRow>
    <CAlert color="primary" :show.sync="showAlert" style="position: absolute; top: 0; right: 0; z-index: 1000; ">
      <p>{{alert.title}}</p>
      <p>{{alert.message}}</p>
    </CAlert>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Denuncias
            <div style="display: flex; gap: 20px; margin-top: 20px">
              Filtros
              <CButton
                style="
                  width: 15px;
                  height: 21px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                @click="collapseFilter = !collapseFilter"
                color="primary"
                class="mb-2"
              >
                <font-awesome-icon
                  :icon="!collapseFilter ? 'plus' : 'minus'"
                  style="font-size: 12px"
                />
              </CButton>
            </div>

            <CCollapse :show="collapseFilter">
              <CCard body-wrapper>
                <CRow>
                  <CCol col="12" md="4">
                    <CDropdown
                      color="primary"
                      toggler-text="Tipos de Denuncia"
                      class="m-2 dropclusters-header"
                    >
                      <div
                        class="selectItem"
                        @click="selectedComplaintTypes = []"
                      >
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <p class="mb-0">Limpiar Filtro</p>
                        </div>
                      </div>
                      <div
                        class="dropbtnn dropdownn selectItem"
                        v-for="item in complaintTypesHeader"
                        :key="item.id"
                        id="all"
                        :class="[
                          selectedComplaintTypes.includes(item.id)
                            ? 'bgSelected'
                            : 'default',
                        ]"
                        @click="selectAllSubtypes(item)"
                      >
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <p class="mb-0">{{ item.title }}</p>

                          <div
                            class="dropdownn dropbtnn"
                            v-if="item.subtypes.length > 0"
                          >
                            <div
                              class="dropdownn-content"
                              style="
                                overflow-y: scroll;
                                min-height: 200px;
                                max-height: 500px;
                              "
                            >
                              <div style="position: relative; overflow: hidden">
                                <div>
                                  <div
                                    style="padding: 6px; gap: 2px"
                                    v-for="subtype in item.subtypes"
                                    :key="subtype.id"
                                    @click="selectSubtype(subtype)"
                                    :class="[
                                      selectedComplaintTypes.includes(
                                        subtype.id
                                      )
                                        ? 'bgSelected'
                                        : 'default',
                                    ]"
                                  >
                                    <div
                                      class="d-flex align-items-center selectItem"
                                    >
                                      <p class="mb-0">{{ subtype.title }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CDropdown>
                  </CCol>
                  <CCol col="12" md="4">
                    <div style="display: flex; align-items: center">
                      <label style="margin-bottom: 0; font-weight: 600"
                        >Desde:
                      </label>
                      <datetime
                        width="100%"
                        class="my-2 mx-4 datepickers__datepicker"
                        type="date"
                        v-model="start_date"
                        :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                      ></datetime>
                    </div>
                    <div style="display: flex; align-items: center">
                      <label style="margin-bottom: 0; font-weight: 600"
                        >Hasta:</label
                      >
                      <datetime
                        width="100%"
                        class="my-2 mx-4 datepickers__datepicker"
                        type="date"
                        v-model="end_date"
                        :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                      ></datetime>
                    </div>
                  </CCol>
                  <CCol col="12" md="4">
                    <div class="mx-2">
                      <CButtonGroup>
                        <CButton
                          size="sm"
                          color="primary"
                          :variant="!video ? '' : 'outline'"
                          @click="video = false"
                          >Todo</CButton
                        >
                        <CButton
                          size="sm"
                          color="primary"
                          :variant="video ? '' : 'outline'"
                          @click="video = true"
                          >Con Video</CButton
                        >
                      </CButtonGroup>
                    </div>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol col="12" md="9">
                    <CRow>
                      <CCol sm="12" md="6">
                      <CInput @keypress="searchLoader = true" class="input-search" style="margin-bottom:0 !important;" label="Excluír usuarios" v-model="search"/>
                      <CListGroup v-if="!searchLoader" style="overflow-y: scroll;height: 186px;">
                        <CListGroupItem @click="togglerUsersSelected(user.id)" :color="selectedUsers.includes(user.id) ? 'dark' : null" href="#" v-for="user in optionsUserNotification" :key="user.id">
                          <p style="margin-bottom: 0;">{{user?.profile?.name}} {{user?.profile?.lastname}} ({{user?.email}})</p>
                        </CListGroupItem>
                        <CListGroupItem v-if="noresults">
                          <p>No se encontraron resultados</p>
                        </CListGroupItem>
                      </CListGroup>
                      <div style="padding: 12px; height: 186px;" v-else>
                    <div class="loader"></div>
                  </div>
                </CCol>
                <CCol sm="12" md="6">
                    <div style="overflow-y: scroll;height: 220px;">
                      <div v-for="user in userNotificationList" :key="user?.value" style="display:inline-block; margin: 4px; margin-top:12px;">
                    <span class="c-multi-select-tag" style="background-color: #ebedef; padding:0.5rem; border-radius: 5px;" v-if="user" >{{user?.label}} <span style="cursor: pointer;" @click="togglerUsersSelected(user.id)">{{user.email}} X</span></span>
                    </div>
                    </div>
                  </CCol>
                </CRow>

                  </CCol>
                  <CCol col="12" md="3"
                    style="display: flex; justify-content: flex-end; gap: 20px"
                  >
                    <CButton color="primary" @click="getComplaints" style="height: 36px;">
                      Filtrar
                    </CButton>
                    <CDropdown color="primary" toggler-text="Descargar">
                      <CDropdownItem @click="exportExcel">Descargar Excel</CDropdownItem>
                      <CDropdownItem @click="exportVideos">Descargar Videos</CDropdownItem>
                    </CDropdown>
                  </CCol>
                </CRow>
              </CCard>
            </CCollapse>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              v-show="!loading"
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="pagination.perPage"
              @row-clicked="checkComplaint"
              index-column
              clickable-rows
            >
              <template #type="data">
                <td class="d-flex align-items-center">
                  <img
                    :src="data.item.type.img_icon"
                    class="mr-1"
                    style="max-width: 25px; max-height: 25px"
                  />
                  {{ data.item.type.title }}
                </td>
              </template>
              <template #user="data">
                <td v-if="data.item.user">
                  {{ data.item.user.name }} {{ data.item.user.lastname }}
                </td>
                <td v-else>Usuario no Registrado</td>
              </template>
              <template #status="data">
                <td>
                  <CBadge :color="getBadge(data.item.status[0].status_id)">
                    {{ data.item.status[0].status.name }}
                  </CBadge>
                  <p class="mb-0" style="font-size: 10px">
                    {{ data.item.status[0].created_at | filterDate }}
                  </p>
                </td>
              </template>
              <template #created="data">
                <td>
                  {{ data.item.created_at | filterDate }}
                </td>
              </template>
              <!--               <template #actions="data">
                <CButton
                  color="warning"
                  size="sm"
                  class="m-1"
                  @click="updateUser(data.item, $event)"
                >
                  <font-awesome-icon icon="user-edit" />
                </CButton>
                <CButton
                  color="danger"
                  size="sm"
                  class="m-1"
                  @click="deleteUser(data.item.id, $event)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </CButton>
              </template> -->
            </CDataTable>
            <div
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center"
              style="min-height: 300px"
            >
              <CSpinner style="width: 4rem; height: 4rem" color="danger" />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <Pagination :data="pagination" :callback="paginate" />
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import Pagination from "../../helpers/Pagination";
import { getComplaintsByCompany, getComplaintTypes } from "@/api/complaints";
import { exportComplaints, exportVideoRecordings } from "@/api/exports";
import { searchUserBySearch } from '@/api/users'

import moment from "moment";
import { mapState } from "vuex";
import _ from 'lodash/lodash'


export default {
  name: "Complaints",
  components: { Pagination },
  filters: {
    filterDate: function (date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
  },
  data: () => {
    return {
      showAlert: false,
      items: [],
      dataLoading: true,
      fields: [
        { key: "unique_id", label: "Código" },
        { key: "type", label: "Tipo" },
        { key: "user", label: "Usuario" },
        { key: "status", label: "Estado Actual" },
        { key: "created", label: "Fecha creación" },
      ],
      pagination: {
        page: 1,
        perPage: 10,
        total: null,
      },
      updatingUser: null,
      showModal: false,
      loading: true,
      collapseFilter: false,
      start_date: null,
      end_date: null,
      video: false,
      selectedComplaintTypes: [],
      SelectAllDisabled: false,
      alert: {
        title: 'Descarga Videos',
        message: 'Se enviará una notificación a su correo una vez que los videos estén disponibles'
      },
      //filter users
      optionsUserNotification: [],
      search: '',
      selectedUser: null,
      selectedUsers: [],
      allSearchResults:[],
      searchLoader: false,
      noresults: false,
      disablebtn: false,
    };
  },
  computed: {
    ...mapState(["complaintTypesHeader"]),
    userNotificationList() {
        const list = [...this.allSearchResults].map(user => {
            if (this.selectedUsers.includes(user.id)) {
                return user
            }
        })
        return list 
    }
  },
  beforeDestroy() {
    this.$events.remove("reloadComplaints");
  },
  mounted() {
    this.getComplaints();
    this.$events.listen("reloadComplaints", () => {
      this.getComplaints();
    });
    this.getComplaintTypes();
  },
  methods: {
    paginate(page, perPage) {
      this.pagination = {
        page: page,
        perPage: perPage,
        total: this.pagination.total,
      };
      this.getComplaints();
    },
    checkComplaint(complaint) {
      this.$store.commit("setShowingComplaint", complaint);
    },
    getComplaints() {
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page,
        start_date: moment(this.start_date).format("DD-MM-YYYY"),
        end_date: moment(this.end_date).format("DD-MM-YYYY"),
        complaint_type_id: this.selectedComplaintTypes,
        video: this.video ? 1 : 0,
      };

      data.start_date == "Invalid date" && delete data.start_date;
      data.end_date == "Invalid date" && delete data.end_date;

      const self = this;
      this.loading = true;
      getComplaintsByCompany(data)
        .then(function (resp) {
          self.items = resp.complaints;
          self.pagination = {
            page: self.pagination.page,
            perPage: self.pagination.perPage,
            total: resp.total,
          };
          self.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getBadge(status) {
      const statusMap = [
        "primary",
        "warning",
        "success",
        "danger",
        "primary",
        "info",
      ];
      return statusMap[status - 1];
    },
    async getComplaintTypes() {
      const complaints = await getComplaintTypes();
      if (complaints.code === 200) {
        this.$store.commit("setComplaintTypes", complaints.complaint_types);
      }
    },
    selectAllSubtypes(item) {
      if (!this.SelectAllDisabled) {
        const subtypesIds = item.subtypes.map((subtype) => {
          return subtype.id;
        });

        let checker = (arr, target) => target.every((v) => arr.includes(v));

        if (checker(this.selectedComplaintTypes, subtypesIds)) {
          this.selectedComplaintTypes = this.selectedComplaintTypes.filter(
            (item) => !subtypesIds.includes(item)
          );
          this.selectedComplaintTypes = this.selectedComplaintTypes.filter(
            (id) => id !== item.id
          );
        } else {
          this.selectedComplaintTypes.push(item.id);
          subtypesIds.forEach((id) => {
            this.selectedComplaintTypes.push(id);
          });

          this.selectedComplaintTypes = [
            ...new Set(this.selectedComplaintTypes),
          ];
        }
      }
    },
    selectSubtype(item) {
      this.SelectAllDisabled = true;
      if (this.selectedComplaintTypes.includes(item.id)) {
        this.selectedComplaintTypes = this.selectedComplaintTypes.filter(
          (typeId) => typeId !== item.id
        );
      } else {
        this.selectedComplaintTypes.push(item.id);
      }
      setTimeout(() => {
        this.SelectAllDisabled = false;
      }, 1000);
    },
    exportExcel() {
      const data = {
        start_date: moment(this.start_date).format("DD-MM-YYYY"),
        end_date: moment(this.end_date).format("DD-MM-YYYY"),
        complaint_type_id: this.selectedComplaintTypes,
        video: this.video ? 1 : 0,
        users_to_exclude: this.selectedUsers
      };

      data.start_date == "Invalid date" && delete data.start_date;
      data.end_date == "Invalid date" && delete data.end_date;

      exportComplaints(data)
        .then((res) => {
          window.open(res.url, "_blank");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    exportVideos() {
      const data = {
        start_date: moment(this.start_date).format("DD-MM-YYYY"),
        end_date: moment(this.end_date).format("DD-MM-YYYY"),
        complaint_type_id: this.selectedComplaintTypes,
        users_to_exclude: this.selectedUsers
      };
      
      data.start_date == "Invalid date" && delete data.start_date;
      data.end_date == "Invalid date" && delete data.end_date;
          console.log(this.toasts);
      exportVideoRecordings(data).then(res => {
        this.showAlert = true;
        setTimeout(() => {
        this.showAlert = false;
        }, 5000);
      }).catch(e => {
        console.log(e);
      })
    },
    searchUsers() {
      if (this.search.length > 2) {
        const data = {search: this.search};
      searchUserBySearch(data).then(res => {
        this.optionsUserNotification = res.users
        const allIds = this.allSearchResults.map(user => user.id)

        res.users.forEach(user => {
          if (!allIds.includes(user.id)) {
            this.allSearchResults.push({...user})
          }
        })
        this.searchLoader = false
        this.noresults = !res.users.length ? true : false
      })
      } else {
        this.searchLoader = false
      }
    },
    togglerUsersSelected(id) {
      if (this.selectedUsers.includes(id)) {
        this.selectedUsers = this.selectedUsers.filter(userid => userid !== id)
        this.optionsUserNotification = this.optionsUserNotification.map(user => {
          return {...user, disabled: this.selectedUsers.includes(user.id)}
        })
      } else {
        this.selectedUsers.push(id)
        this.optionsUserNotification = this.optionsUserNotification.map(user => {
          return {...user, disabled: this.selectedUsers.includes(user.id)}
        })
      }
    },
  },
  watch: {
    search: _.debounce(function (value) {
      this.searchUsers()
    }, 1000)
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/style.scss";

.dropdownn {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: space-between;
}

.dropdownn-content {
  display: none;
  position: absolute;
  top: -30px;
  left: 27px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownn-content a {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdownn-content a:hover {
  background-color: #f1f1f1;
}

.dropdownn:hover .dropdownn-content,
.dropdownn-content:hover {
  display: block;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #afaeae #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.selectItem {
  display: flex;
  width: 185px;
  cursor: pointer;
  padding: 12px;
}
.bgSelected {
  background-color: $color-primary;
  border: solid 1px #fff;
}
.default {
  background-color: #ffffff;
  border: solid 1px #fff;
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     #fcee28 96%,#97440000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     #cedf3a 96%,#aa310100) 0 50%/80% 20% repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}
</style>
